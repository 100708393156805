import styled from "styled-components";

import { red, white } from "theme/colors";
import { breakpoints } from "utils/mixins";

export const BikeStyles = styled.div`
  width: 100%;
  padding: 0 0 90px;
  position: relative;

  @media ${breakpoints.laptop} {
    margin: ${(props) => (props.fullWidth ? "" : "20px")};
    width: ${(props) => (props.fullWidth ? "100%" : "calc(33% - 40px)")};
  }

  .bike-footer {
    padding-top: 2rem;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-more {
      a {
        text-align: center;
        display: block;
        padding: 10px;
        color: ${white.default};
        background-color: ${red.default};
        text-transform: uppercase;
        min-width: 150px;
        margin: 15px;
        text-decoration: none;
      }
    }
    &-media {
    }
  }
  .bike-image {
    position: relative;
    width: 100%;
    padding-top: ${(props) => (props.fullWidth ? "0" : "75%")};
    max-height: ${(props) => (props.fullWidth ? "800px" : "unset")};
    &-full {
      // aspect-ratio: ${(props) => (props.fullWidth ? "unset" : "4/3")};
      //width: 100%;

      //max-height: ${(props) => (props.fullWidth ? "800px" : "unset")};

      position: ${(props) => (props.fullWidth ? "relative" : "absolute")};
      top: 0;
      left: 0;
      width: ${(props) => (props.fullWidth ? "auto" : "100%")};
      height: ${(props) => (props.fullWidth ? "auto" : "100%")};
      object-fit: ${(props) => (props.fullWidth ? "contain" : "cover")};
    }
    &-expand {
      position: absolute;
      right: 10px;
      bottom: 10px;
      width: 20px;
      height: 20px;
      z-index: 2;
    }
  }

  h4 {
    font-size: 20px;
    margin-bottom: 8px;
    margin-top: 0px;
  }

  span {
    color: ${red.default};
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 14px;
  }

  li {
    font-weight: lighter;
    font-size: 20px;
    /* Non standard for WebKit */
    word-break: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    -ms-word-break: break-all;
    word-break: break-all;
  }

  a {
    color: blue;
    text-decoration: underline;
  }

  p {
    font-size: 15px;
    margin-bottom: 10px;
  }

  .m_dicas {
    &__social-item {
      display: inline-block;
      height: 35px;
      width: 35px;
      overflow: hidden;
      border-radius: 50%;
      background-color: $color__green;
      transition: all 0.3s;
      margin: 0 2px;
      button {
        margin: 0 2px;
        display: inline-block;
        height: 35px;
        width: 35px;
        overflow: hidden;
        border-radius: 50%;
        background-color: $color__green;
        transition: all 0.3s;
      }
      &:hover {
        background-color: $color__green;
      }

      img {
        height: 100%;
        min-width: 35px;
        max-width: 1000px;
        width: auto;
        margin-left: 0;
        transition: all 0.3s;
      }

      &--face {
        background-color: #1877f2;

        img {
          margin-left: -5px;

          &:hover {
            margin-left: -53px;
          }
        }
      }

      &--email {
        background-color: #f15a29;

        &:hover {
        }

        img {
          &:hover {
            margin-left: -35px;
          }
        }
      }
    }
  }
`;
