import React, { useEffect, useRef } from "react";

import Slider from "react-slick";

import gsap from "gsap";

import { LightboxContainer, Close, Slide, Slideshow } from "./Lightbox.styles";

const Lightbox = (props) => {
  //PROPS
  const { lightBox, setLightBox } = props;
  //PROPS

  //GSAP
  const tl = gsap.timeline();
  const introAnim = () => {
    tl.to(".slider-car", {
      duration: 0.01,
      css: {
        zIndex: "201"
      }
    }).to(".slider-car", {
      duration: 0.4,
      css: {
        opacity: 1
      }
    });
  };

  const closeAnim = () => {
    tl.to(".slider-car", {
      duration: 0.4,
      css: {
        opacity: 0
      }
    }).to(".slider-car", {
      duration: 0.01,
      css: {
        zIndex: "-1"
      }
    });
  };
  //GSAP

  //DEFS
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true
  };
  //DEFS

  //REFS
  const slider = useRef(null);
  //REFS

  //EFFECTS
  useEffect(() => {
    if (lightBox.showLightbox) {
      introAnim();
      document.querySelector("body").classList.add("noover");
    } else {
      document.querySelector("body").classList.remove("noover");
      closeAnim();
    }
  }, [lightBox, introAnim, closeAnim]);
  //EFFECTS

  return (
    <LightboxContainer className="slider-car">
      <Close onClick={() => setLightBox({ ...lightBox, showLightbox: false })}>
        <img src="/images/close.svg" alt="close" />
      </Close>
      <Slideshow>
        <Slider
          {...settings}
          style={{ height: "100vh" }}
          ref={slider}
          key={lightBox.showLightbox}
        >
          {lightBox.images &&
            lightBox.images.map((image, index) => {
              return (
                <Slide key={index}>
                  <img
                    src={process.env.MEDIA_URL + image.path}
                    alt={image.name}
                  />
                </Slide>
              );
            })}
        </Slider>
      </Slideshow>
    </LightboxContainer>
  );
};

export default Lightbox;
