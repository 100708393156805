import React, { useState } from "react";

import BikeCard from "components/bikeCard";

const Motas = (props) => {
  const [lightBox, setLightBox] = useState({
    showLightbox: false,
    images: null
  });

  return (
    <main className="main">
      <div className="container">
        <BikeCard
          data={props.pageContext.bike}
          fullWidth
          lightBox={lightBox}
          setLightBox={setLightBox}
        />
      </div>
    </main>
  );
};

export default Motas;
