import { Link } from "gatsby";
import React from "react";
import slugify from "slugify";
import Lightbox from "../ligthbox/Lightbox";
import Share from "../share/Share";

import { BikeStyles } from "./BikeCard.styles";

const BikeCard = (props) => {
  const {
    lightBox,
    setLightBox,
    data: {
      id,
      media,
      model,
      brand,
      price,
      cc,
      type,
      month,
      year,
      kms,
      cv,
      location,
      email,
      phone
    }
  } = props;

  let link =
    `${process.env.GATSBY_SITE}/oportunidades/` +
    slugify(brand.name + "-" + model + "-" + id, {
      lower: true // convert to lower case, defaults to `false`
    }) +
    "/";

  return (
    <>
      <BikeStyles fullWidth={props.fullWidth}>
        {media[0]?.path && (
          <button
            className="bike-image"
            onClick={() => {
              setLightBox({
                images: media,
                showLightbox: true
              });
            }}
          >
            <img
              src={process.env.MEDIA_URL + media[0]?.path}
              alt={model}
              className="bike-image-full"
            />
            <img
              src="/images/expand.png"
              alt="Fullscreen logo"
              className="bike-image-expand"
            />
          </button>
        )}
        <h4 className="brand" style={{ marginTop: "14px" }}>
          <b>{brand.name}</b>
        </h4>
        <h4 className="model">{model}</h4>
        <span>{price} €</span>
        <ul>
          <li>
            Cilindrada (c.c.): <b>{cc}</b>
          </li>
          <li>
            Tipo: <b>{type}</b>
          </li>

          <li>
            Matrícula:{" "}
            {month !== "0" && year !== "0" && (
              <b>
                {month}/{year}
              </b>
            )}
          </li>

          <li>
            KM: <b>{kms}</b>
          </li>
          <li>
            Potência (cv): <b>{cv}</b>
          </li>
          <li>
            Localização: <b>{location.location}</b>
          </li>
          <li>
            E-mail:{" "}
            <a href={"mailto:" + email} rel="noreferrer">
              <b>{email}</b>
            </a>
          </li>
          <li>
            Telefone:{" "}
            <a href={"tel:" + phone} rel="noreferrer">
              <b>{phone}</b>
            </a>
          </li>
        </ul>
        <div className="bike-footer">
          <div className="bike-footer-media">
            <p>Partilhar:</p>

            <ul className="m_dicas__social">
              <li className="m_dicas__social-item">
                <a
                  target="_blank"
                  className="email share"
                  href={`http://www.facebook.com/share.php?u=${link}`}
                  title="Facebook"
                  rel="noopener noreferrer"
                >
                  <img src="/images/icons/facebook.png" alt="Facebook logo" />
                </a>
              </li>

              <li className="m_dicas__social-item ">
                <a
                  className="email share"
                  href={`mailto:?subject=${model} - Oportunidades Motospazio&body=Acho que vais gostar desta moto - <a style="cursor:pointer" href="${link}">${link}</a>`}
                  title="Email"
                >
                  <img src="/images/icons/email.png" alt="Instagram logo" />
                </a>
              </li>
              <Share uri={link} />
            </ul>
          </div>

          {props.fullWidth && (
            <div className="bike-footer-more">
              <Link to="/oportunidades">Ver Mais</Link>
            </div>
          )}
        </div>
      </BikeStyles>
      <Lightbox lightBox={lightBox} setLightBox={setLightBox} />
    </>
  );
};

export default BikeCard;
